import { getProviderKeyByType, getProviderKey } from '@noda-lib/assets/tools';
import { InjectionToken } from '@angular/core';
import { replaceTags } from '@noda-lib/utils/handlers';
function defaultLocalizeFn(value, params) {
  return replaceTags(value, params);
}
const ASSETS_LANG = new InjectionToken('ASSETS_LANG', {
  factory() {
    return 'en';
  }
});
const ASSETS_LOCALIZE_FN = new InjectionToken('ASSETS_LOCALIZE_FN', {
  factory() {
    return defaultLocalizeFn;
  }
});
function withLocalizeFn(fn, type = 'value') {
  const providerKey = getProviderKeyByType(type);
  return [{
    provide: ASSETS_LOCALIZE_FN,
    [providerKey]: fn
  }];
}
function withLocalizeLanguage(lang) {
  const key = getProviderKey(lang);
  return [{
    provide: ASSETS_LANG,
    [key]: lang
  }];
}

/**
 * Generated bundle index. Do not edit.
 */

export { ASSETS_LANG, ASSETS_LOCALIZE_FN, withLocalizeFn, withLocalizeLanguage };
